import { AppShell, AppShellAside, AppShellMain, Container } from '@mantine/core';
import SessionProvider from '../../components/SessionProvider';
import React, { ReactNode } from 'react';
import Navbar from '../../components/Layout/Navbar';
import { ModalsProvider } from '@mantine/modals';
import useSelfAuditToggle from '../../components/SelfAuditView/useSelfAuditToggle';
import SelfAuditPanel from '../../components/SelfAuditView/SelfAuditPanel';

const InternalShell = ({ children }: { children: ReactNode }) => {
  const { open: showAside, closePanel, activePolicyId } = useSelfAuditToggle();

  return (
    <SessionProvider>
      <ModalsProvider>
        <AppShell
          bg="gray.1"
          mih="100vh"
          pb="xl"
          header={{ height: 65, offset: true }}
          padding="md"
          aside={{
            width: 500,
            breakpoint: 'md',
            collapsed: { desktop: !showAside },
          }}
        >
          <Navbar />
          <AppShellMain>
            <Container maw="2040">{children}</Container>
          </AppShellMain>
          {showAside && (
            <AppShellAside>
              <SelfAuditPanel policyId={activePolicyId} closePanel={closePanel} />
            </AppShellAside>
          )}
        </AppShell>
      </ModalsProvider>
    </SessionProvider>
  );
};

export default InternalShell;
