import {
  Box,
  Button,
  Group,
  AppShellHeader,
  Menu,
  Select,
  Tooltip,
  Container,
  Avatar,
  Loader,
} from '@mantine/core';
import Link from 'next/link';
import { useHotkeys } from '@mantine/hooks';
import React, { useCallback, useRef } from 'react';
import { useLogout, useSession } from '../../hooks/useSession';
import justinHead from '../../../public/images/justin-head.png';
import Image from 'next/image';
import FullLogo from '../Logos/FullLogo';
import { usePolicyholdersNamesQuery } from '../../graphql/operations/policyholders.generated';
import { Search } from '@emotion-icons/ionicons-outline/Search';
import { IS_PREVIEW, IS_PRODUCTION, IS_AUDIT } from '../../constants';
import { useRecentlyViewed } from '../../utils/useRecentlyViewed';
import { useRouter } from 'next/navigation';
const SearchBar = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { data, loading } = usePolicyholdersNamesQuery({});

  const recent = useRecentlyViewed();

  const onSearchChange = useCallback(
    (value: any) => {
      router.push(value);
    },
    [router]
  );

  useHotkeys([['mod+K', () => searchRef.current?.focus()]]);

  const recentlyViewed =
    recent.map((item) => ({
      label: item.label,
      value: item.url,
    })) ?? [];

  const searchResults =
    data?.policyholdersNames?.map((policyholder) => ({
      label: `${policyholder.name}${policyholder.deletedAt ? ' (archived)' : ''}`,
      value: `/u/policyholders/${policyholder.id}`,
    })) ?? [];

  return (
    <Select
      style={{ flex: '1' }}
      maw="480"
      disabled={loading}
      ref={searchRef}
      searchable
      onChange={onSearchChange}
      placeholder="Search"
      size="xs"
      radius="xl"
      limit={10}
      leftSection={loading ? <Loader size="xs" /> : <Search size={14} />}
      rightSection={<span />}
      data={[
        { group: 'Recently Viewed', items: recentlyViewed },
        { group: 'Search Results', items: searchResults },
      ]}
    />
  );
};

const AdminNavLinks = () => {
  return (
    <Menu trigger="hover">
      <Menu.Target>
        <Button variant="transparent" size="compact-sm">
          Admin
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item component={Link} href="/u/brokerages">
          Brokerages
        </Menu.Item>
        <Menu.Item component={Link} href="/u/carriers">
          Carriers
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://docs.google.com/spreadsheets/d/10FV1ZubRNO15NRHv14ETKTCK2z7G_MT-Y7zqv5S2Qzc/edit#gid=0"
          target="_blank"
        >
          Licenses
        </Menu.Item>
        <Menu.Item component={Link} href="/u/form-management">
          Form Management
        </Menu.Item>
        <Menu.Item component={Link} href="/u/platform-defaults">
          Platform Defaults
        </Menu.Item>
        <Menu.Item component={Link} href="/u/underwriting-warnings">
          Underwriting Warnings
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const getNavColor = () => {
  if (IS_PREVIEW) {
    return 'green.1';
  }
  if (IS_PRODUCTION) {
    return 'white';
  }

  return 'cyan.1';
};
const Navbar = ({ forInsured = false }: { forInsured?: boolean }) => {
  const { session } = useSession();
  const logout = useLogout();
  const orgLogoUrl = session?.organization?.logoUrl;

  return (
    <AppShellHeader bg={getNavColor()} p="sm" zIndex={100}>
      <Container maw="2040" style={{ zIndex: 101 }}>
        <Group align="center" justify="space-between">
          <Link legacyBehavior href={'/u'} passHref>
            <a
              className="logo"
              style={{
                float: 'left',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                flexDirection: 'column',
                paddingRight: 12,
              }}
            >
              {!IS_AUDIT &&
              (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) === 'preview' ? (
                <>
                  <Tooltip label="Hype Hype Hype" position="right">
                    <Box className="d-flex align-items-center">
                      <Image alt="alt" src={justinHead} height={30} width={24} />
                      <Image alt="alt" src={justinHead} height={30} width={24} />
                      <Image alt="alt" src={justinHead} height={30} width={24} />
                    </Box>
                  </Tooltip>
                </>
              ) : (
                <>
                  <FullLogo />
                </>
              )}
              {orgLogoUrl ? (
                <div
                  style={{
                    height: 64,
                    padding: '16px 0px',
                  }}
                  className="d-flex align-items-center"
                >
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={orgLogoUrl} height="100%" alt="Organization logo" />
                </div>
              ) : null}
            </a>
          </Link>
          {session?.user?.id && <SearchBar />}
          {forInsured ? (
            <div
              style={{ flex: '1', justifyContent: 'end', alignItems: 'center', display: 'flex' }}
            >
              <Button component="a" href="mailto:support@withshepherd.com">
                Need help?
              </Button>
            </div>
          ) : (
            session?.user?.id && (
              <Group gap={16}>
                <Button variant="transparent" size="compact-sm" component={Link} href="/u/policies">
                  Policies
                </Button>
                <Button
                  variant="transparent"
                  size="compact-sm"
                  component={Link}
                  href="/u/policyholders"
                >
                  Policyholders
                </Button>
                <AdminNavLinks />
                <Menu>
                  <Menu.Target>
                    <Button p="0" variant="transparent">
                      <Avatar src={session?.user?.imageUrl} radius="xl" size="sm">
                        {session?.user?.initials}
                      </Avatar>
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>
                      {session?.user?.name}
                      {session?.organization?.name ? ` (${session?.organization?.name})` : ''}
                    </Menu.Label>
                    <Menu.Divider />
                    <Menu.Item key="logout" onClick={() => logout()}>
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            )
          )}
        </Group>
      </Container>
    </AppShellHeader>
  );
};

export default Navbar;
