import {
  Button,
  Select,
  SimpleGrid,
  Text,
  Title,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionControl,
  Group,
  Stack,
  Divider,
  ActionIcon,
  Textarea,
  Loader,
  Flex,
  CloseButton,
} from '@mantine/core';
import {
  SelfAuditQuery,
  useRunSelfAuditAutoDetectionMutation,
  useSelfAuditQuery,
  useUpdateSelfAuditMutation,
} from '../../graphql/operations/audits.generated';
import { Controller, useForm } from 'react-hook-form';
import React, { useContext } from 'react';
import onApolloError, { getCorrectDateWithTimezoneOffset } from '../../utils/utils';
import { namedOperations } from '../../graphql/namedOperations.generated';
import { isNil } from 'lodash';
import { AuditScore, AuditQuestionResponse, SelfAuditStatus } from '../../graphql/types.generated';
import { humanizeEnum, humanizeStringEveryUpper } from '../../utils/humanizeString';
import AssigneeId from './Fields/AssigneeId';
import { DateInput } from '@mantine/dates';
import { DATE_FORMAT, SELF_AUDIT_FEAT_RELEASE_DATE } from '../../constants';
import SelfAuditAutoDetectIcon from '../SelfAudit/SelfAuditAutoDetectIcon';
import SelfAuditSectionScore from '../SelfAudit/SelfAuditSectionScore';
import { Refresh } from '@emotion-icons/ionicons-outline';
import { IconExclamationCircle, IconMessage } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { NetworkStatus } from '@apollo/client';
import { ConfettiContext } from '../ConfettiProvider';
import dayjs from 'dayjs';
import PageHeader from '../PageHeader/PageHeader';
import { openConfirmModal } from '@mantine/modals';
import ShepherdActionIcon from '../ShepherdActionIcon';

const getSelectedValueColor = (selectedValue: string) => {
  switch (selectedValue) {
    case AuditScore.NoInformation:
      return 'var(--mantine-color-red-2)';
    case AuditScore.NotApplicable:
      return 'var(--mantine-color-gray-2)';
    case AuditScore.StronglyDisagree:
      return 'var(--mantine-color-orange-3)';
    case AuditScore.Disagree:
      return 'var(--mantine-color-orange-1)';
    case AuditScore.Agree:
    case AuditScore.StronglyAgree:
    default:
      return '';
  }
};

const isSelfAuditComplete = (status: SelfAuditStatus) => {
  return status === SelfAuditStatus.Complete;
};

type AuditFormProps = {
  policyId: string;
  closePanel: () => void;
};

const AuditForm = ({ policyId, closePanel }: AuditFormProps) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const { fireConfetti } = useContext(ConfettiContext);

  const [runSelfAuditAutoDetection, { loading: loadingRunSelfAuditAutoDetection }] =
    useRunSelfAuditAutoDetectionMutation({
      onError: onApolloError(),
      refetchQueries: [namedOperations.Query.SelfAudit, namedOperations.Query.Policy],
    });

  const { data: selfAuditData, networkStatus } = useSelfAuditQuery({
    variables: { policyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: onApolloError(),
    onCompleted: (response) => {
      const formData: Record<string, string | null | undefined> = {
        auditorId: response.selfAudit.auditorId,
        completedAt: response.selfAudit.completedAt,
        status: response.selfAudit.status,
      };

      response.selfAudit.auditQuestionResponses.forEach((auditQuestionResponse) => {
        formData[`score_${auditQuestionResponse.auditQuestionId}`] = auditQuestionResponse.score;
        formData[`comment_${auditQuestionResponse.auditQuestionId}`] =
          auditQuestionResponse.comment;
      });

      reset(formData);
    },
  });

  const [updateSelfAudit, { loading: loadingUpdateSelfAudit }] = useUpdateSelfAuditMutation({
    onError: onApolloError(),
    refetchQueries: [namedOperations.Query.SelfAudit],
    onCompleted: (data) => {
      showNotification({ message: 'Self Audit Updated', color: 'green' });

      if (isSelfAuditComplete(data.updateSelfAudit.status)) {
        fireConfetti();
      }
    },
  });

  if (networkStatus === NetworkStatus.loading || loadingRunSelfAuditAutoDetection) {
    return (
      <Flex justify="center" align="center">
        <Loader />
      </Flex>
    );
  }

  if (!selfAuditData?.selfAudit) {
    return (
      <Group justify="center">
        <CloseButton onClick={() => closePanel()} />
        <IconExclamationCircle color="red" />
        <Title order={5}>No Self-Audit found for this policy</Title>
      </Group>
    );
  }

  const filePrepDueDate = dayjs(selfAuditData.policy.effectiveDate)
    .add(30, 'day')
    .format('MM/DD/YYYY');

  const onSubmit = handleSubmit(async (formData) => {
    const updatedResponses = selfAuditData.selfAudit.auditQuestionResponses.map(
      (auditQuestionResponse) => {
        return {
          auditQuestionId: auditQuestionResponse.auditQuestionId,
          comment: formData[`comment_${auditQuestionResponse.auditQuestionId}`],
          score: formData[`score_${auditQuestionResponse.auditQuestionId}`],
        };
      }
    );

    const formattedCompletedAt = !isNil(formData.completedAt)
      ? dayjs(formData.completedAt).format('YYYY-MM-DD')
      : null;

    await updateSelfAudit({
      variables: {
        selfAuditId: selfAuditData.selfAudit.id,
        auditorId: formData.auditorId,
        completedAt: formattedCompletedAt,
        status: formData.status,
        auditQuestionResponses: updatedResponses,
      },
    });
  });

  // group the audit questions by audit section
  const auditQuestionResponsesByAuditSection =
    selfAuditData.selfAudit.auditQuestionResponses.reduce<
      Record<string, SelfAuditQuery['selfAudit']['auditQuestionResponses']>
    >((acc, auditQuestionResponse) => {
      const { auditSection } = auditQuestionResponse;
      acc[auditSection] = acc[auditSection] ?? [];
      acc[auditSection].push(auditQuestionResponse as AuditQuestionResponse);

      return acc;
    }, {});

  const values = watch();

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <Stack gap="0">
          <Group justify="space-between">
            <Title order={3}>Self Audit</Title>
            <CloseButton
              onClick={() => {
                openConfirmModal({
                  title: 'Are you sure? You will lose all unsaved data',
                  confirmProps: { children: 'Confirm' },
                  cancelProps: { children: 'Cancel' },
                  onConfirm: () => closePanel(),
                  onCancel: () => {},
                });
              }}
            />
          </Group>
          <PageHeader
            title={{
              label: `${selfAuditData.policy.name} (${selfAuditData.policy.effectiveDate})`,
              href: `/u/policies/${selfAuditData.policy.id}`,
              width: '15rem',
            }}
            order={6}
            precedingLinks={[
              {
                href: `/u/policyholders/${selfAuditData.policy.policyholder.id}`,
                name: `${selfAuditData.policy.policyholder.name}`,
              },
            ]}
          />
          <Stack gap="0">
            <Group justify="space-between">
              <Title order={6}>{`File Prep Due Date: ${filePrepDueDate}`}</Title>
              {/* button to run self-audit auto-detection for all questions that have an auto-detection function provisioned */}
              <ShepherdActionIcon
                tooltip="Refresh Self-Audit Auto Detection"
                icon={Refresh}
                disabled={isSelfAuditComplete(selfAuditData.selfAudit.status)}
                onClick={async () =>
                  runSelfAuditAutoDetection({
                    variables: {
                      policyId,
                    },
                  })
                }
              />
            </Group>
            {/* self-audit panel was introduced after 2/1, so we don't want to confuse users with a false-negative/false-positive score */}
            {dayjs(selfAuditData.policy.effectiveDate).isAfter(SELF_AUDIT_FEAT_RELEASE_DATE) && (
              <Title order={6}>
                {<SelfAuditSectionScore section={'Total'} selfAudit={selfAuditData.selfAudit} />}
              </Title>
            )}
          </Stack>
        </Stack>
        <Divider />
        <SimpleGrid cols={2}>
          <AssigneeId control={control} name="auditorId" label="Auditor" />
          <Controller
            control={control}
            name="status"
            render={({ field, fieldState }) => {
              return (
                <Select
                  {...field}
                  label={'Status'}
                  error={fieldState.error?.message}
                  required={true}
                  data={[
                    {
                      label: humanizeEnum(SelfAuditStatus.FilePrepOutstanding),
                      value: SelfAuditStatus.FilePrepOutstanding,
                    },
                    {
                      label: humanizeEnum(SelfAuditStatus.FilePrepComplete),
                      value: SelfAuditStatus.FilePrepComplete,
                    },
                    {
                      label: humanizeEnum(SelfAuditStatus.ChangesRequested),
                      value: SelfAuditStatus.ChangesRequested,
                    },
                    {
                      label: humanizeEnum(SelfAuditStatus.CompleteExcludingPolicy),
                      value: SelfAuditStatus.CompleteExcludingPolicy,
                    },
                    {
                      label: humanizeEnum(SelfAuditStatus.Complete),
                      value: SelfAuditStatus.Complete,
                    },
                  ]}
                  searchable
                />
              );
            }}
          />
        </SimpleGrid>
        <SimpleGrid cols={2}>
          <Controller
            control={control}
            name="completedAt"
            render={({ field, fieldState }) => (
              <DateInput
                {...field}
                valueFormat={DATE_FORMAT}
                error={fieldState.error?.message}
                label="Audit Completed Date"
                value={field.value ? getCorrectDateWithTimezoneOffset(field.value) : null}
              />
            )}
          />
        </SimpleGrid>
        <Accordion multiple chevronPosition="left" variant="separated">
          {/* iterate through all associated audit questions to display questions, responses, and comments */}
          {Object.entries(auditQuestionResponsesByAuditSection).map(([section, responses]) => (
            <AccordionItem value={section} key={section}>
              <AccordionControl>
                <Group justify="space-between">
                  <Text size="md" fw="bold">
                    {humanizeStringEveryUpper(section)}
                  </Text>
                  <SelfAuditSectionScore section={section} selfAudit={selfAuditData.selfAudit} />
                </Group>
              </AccordionControl>
              <AccordionPanel>
                <Stack gap="lg">
                  {responses.map((response) => (
                    <Stack gap="xs" key={response.auditQuestionId}>
                      <Group align="flex-end">
                        <Controller
                          name={`score_${response.auditQuestionId}`}
                          control={control}
                          // if the question was auto-scored, then do not allow user to override
                          render={({ field, fieldState }) => {
                            const dataOptions = [
                              { label: 'No information', value: AuditScore.NoInformation },
                              {
                                label: 'Strongly disagree with statement',
                                value: AuditScore.StronglyDisagree,
                              },
                              { label: 'Disagree with statement', value: AuditScore.Disagree },
                              {
                                label: 'Agree with statement, but missing minor info or analysis',
                                value: AuditScore.Agree,
                              },
                              {
                                label: 'Strongly agree with statement',
                                value: AuditScore.StronglyAgree,
                              },
                            ];

                            if (!response.required) {
                              dataOptions.push({
                                label: 'Not applicable',
                                value: AuditScore.NotApplicable,
                              });
                            }

                            return (
                              <Select
                                {...field}
                                disabled={
                                  response.shouldAutoScore ||
                                  isSelfAuditComplete(selfAuditData.selfAudit.status)
                                }
                                styles={{
                                  input: { backgroundColor: getSelectedValueColor(field.value) },
                                }}
                                style={{ flex: 1 }}
                                label={
                                  <Text fw="normal" pb="xs" style={{ verticalAlign: 'middle' }}>
                                    {`${response.auditQuestionId}. ${response.auditQuestionLabel}`}
                                    {response.required && (
                                      <Text span c="red">
                                        *
                                      </Text>
                                    )}{' '}
                                    <SelfAuditAutoDetectIcon
                                      autoDetectValue={response.autoDetectedScore}
                                    />
                                  </Text>
                                }
                                error={fieldState.error?.message}
                                data={dataOptions}
                              />
                            );
                          }}
                        />
                        <ActionIcon
                          disabled={isSelfAuditComplete(selfAuditData.selfAudit.status)}
                          pb="xs"
                          onClick={() => {
                            const currentValue = values[`comment_${response.auditQuestionId}`];
                            if (isNil(currentValue)) {
                              setValue(`comment_${response.auditQuestionId}`, '');
                            }
                            // allow hiding empty comment fields
                            if (typeof currentValue === 'string' && currentValue.trim() === '') {
                              setValue(`comment_${response.auditQuestionId}`, null);
                            }
                          }}
                          variant="white"
                        >
                          <IconMessage size={20} stroke={1.5} />
                        </ActionIcon>
                      </Group>
                      {!isNil(values[`comment_${response.auditQuestionId}`]) && (
                        <Controller
                          name={`comment_${response.auditQuestionId}`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <Textarea
                              {...field}
                              error={fieldState.error && fieldState.error.message}
                              placeholder="Comment"
                            />
                          )}
                        />
                      )}
                    </Stack>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        <Group justify="flex-end">
          <Button type="submit" disabled={loadingUpdateSelfAudit} loading={loadingUpdateSelfAudit}>
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default AuditForm;
